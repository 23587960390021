// 1rem = 16px by default

@use "sass:color";
@use "sass:math";

// 1rem = 16px by default

$font_size: 1rem;
$line_height: 2;

$page_height: 100vh;
$page_height_dynamic: 100dvh;
$page_height_small: 100svh;
$page_height_large: 100lvh;

$page_gap_0: 0.25rem;
$page_gap_1: 0.5rem;
$page_gap_2: 1rem;
$page_gap_3: 1.5rem;
$page_gap_4: 2rem;
$page_gap_5: 2.5rem;
$page_gap_6: 3rem;
$page_gap_7: 3.5rem;
$page_gap_8: 4rem;
$page_gap_9: 4.5rem;

$nav_header_height: 6rem;
$nav_header_border: 0.1rem;
$nav_footer_height: 80px;
$nav_header_z_index: 3;

$sticky_content_z_index: 3;

$padding_textbox: $page_gap_1 $page_gap_2;

$animation_duration_structure: 500ms;

// =====================================================================================

$theme_color: #00aaff; // #0af (hue = 200, saturation = 100%, lightness = 100%)
// $theme_color: #aca; // #0af (hue = 200, saturation = 100%, lightness = 100%)
// $theme_color_article: #000;
$theme_color_shadow: #000000;

$body_bg_color: color.adjust($theme_color, $lightness: -37.5%);

$font_family: "Helvetica Neue", Helvetica, Arial, sans-serif;
// $font_color: color.adjust($theme_color, $saturation: -78%, $lightness: 30%);
$font_color: #ffffff;
// $font_color: color.adjust($theme_color, $saturation: -78%, $lightness: 50%);
// $font_color_nav_header: color.adjust($theme_color, $saturation: -78%, $lightness: 40%);
$font_color_nav_header: #ffffff;

$link_color: color.adjust($theme_color, $saturation: -50%, $lightness: 17.5%);

$button_background_color: color.adjust($theme_color, $saturation: -20%, $lightness: -30%);
$button_link_color: color.adjust($theme_color, $saturation: -50%, $lightness: 35%);

// $seperator_opacity: 0.125;
$seperator_opacity: 0.1875;
// $seperator_opacity_header: 0.375;
$seperator_opacity_header: 0.3125;
$seperator_color: rgba($font_color, $seperator_opacity);
$seperator_color_header: rgba($font_color, $seperator_opacity_header);

$selection_background: $theme_color;
$selection_color:      #ffffff;

$page_color_shadow_darker:   rgba($theme_color_shadow, 0.4);
$page_color_shadow_dark:     rgba($theme_color_shadow, 0.25);
$page_color_shadow_light:    rgba($theme_color_shadow, 0.2);
$page_color_shadow_lighter:  rgba($theme_color_shadow, 0.15);
$page_color_shadow_lightest: rgba($theme_color_shadow, 0.1);

$boxshadow_inset: inset 0 8px 8px $page_color_shadow_lighter, inset 0 -4px 4px $page_color_shadow_lightest;

$radius_0: 0.25rem;
$radius_1: 0.5rem;
$radius_2: 1rem;
$radius_3: 1.5rem;
$radius_4: 2rem;
$radius_5: 2.5rem;
$radius_6: 3rem;
$radius_7: 3.5rem;
$radius_8: 4rem;

@mixin button_box_shadow_default {
  box-shadow: 0 $page_gap_0 $page_gap_0 * 0.5 $page_color_shadow_lighter;
}

@mixin button_box_shadow_hover {
  box-shadow: none;
}

@mixin button_box_shadow_active {
  box-shadow: inset 0 $page_gap_0 $page_gap_0 * 0.5 $page_color_shadow_dark, inset 0 $page_gap_0 * -0.5 $page_gap_0 * 0.25 $page_color_shadow_lighter;
}

$animation_duration_interact: 100ms;

@mixin border_focus {
  // border: 0.1rem solid $link_color;
  border: 0.1rem solid yellow;
}
